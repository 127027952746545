<template lang="html">
  <div class="Dashboard">
    <dashboard-top>
      <screen-title :title="this.$store.state.user.team.name" action="events/new" :actionPermission="userIs('admin')" button="Create Event"  v-if="userNot('player')"></screen-title>
      <screen-title title="My Games" v-else></screen-title>
    </dashboard-top>

    <div class="container-full">
      <section-title title="Games">
        <action icon="rubbish-bin" v-if="loaded && games.length > 0"></action>
      </section-title>
      <fade-transition mode="out-in">
        <TableLoader v-if="!loaded" />
        <Table :items="games" :key="games.length" v-if="loaded && games.length > 0" checked></Table>
        <empty-message v-if="loaded && games.length == 0">
          No games found!
        </empty-message>
      </fade-transition>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import TableLoader from '@/components/Loaders/TableLoader';
import Table from '@/components/Tables/GamesTable';
export default {
  name: 'GameList',
  components: {
    Table,
    TableLoader,
  },
  created() {
    this.$store.dispatch('games/GET_GAMES');
  },
  computed: {
    ...mapState({
      games: state => state.games.all,
      loaded: state => state.games.status.all == 'success'
    })
  }
}
</script>

<style lang="scss">
</style>
