<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :allChecked="allChecked"
          :checked="checked"
          @markAll="markAll"
          :columns="table.header" />

        <main class="Table__body">
          <div
            class="TableItem"
            v-for="item in chunkedList[this.listQuery.page - 1]"
            :key="item.id">
            <div class="TableColumn TableColumn--check" v-if="checked">
              <Checkbox
                :name="item.name"
                :value="item.id"
                v-model="selectedItems"></Checkbox>
            </div>
            <router-link
              :to="{ name: 'game', params: { id: item.id } }"
              class="TableLink">
              <div class="TableColumn TableColumn--short">
                {{ item.id }}
              </div>
              <div class="TableColumn TableColumn--medium">
                {{ convertTime(item.event.date) | moment('DD/mm/YYYY') }}
              </div>
              <div class="TableColumn TableColumn--flex">
                {{ item.event.name }}
              </div>
              <div class="TableColumn TableColumn--medium">
                {{ item.court.name }}
              </div>
            </router-link>
            <div class="TableColumn TableColumn--large url">
              {{ url }}r/{{ item.event.slug }}
            </div>
          </div>
        </main>
      </div>
    </simplebar>

    <footer>
      <Pagination
        :total="this.items.length"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"></Pagination>
    </footer>
  </div>
</template>

<script>
  import Checkbox from '@/partials/Forms/Checkbox';

  // Mixins
  import TableMixin from '@/mixins/TableMixin';
  import TimeHelper from '@/helpers/TimeHelper';

  export default {
    mixins: [TableMixin],
    name: 'GamesTable',
    components: {
      Checkbox,
    },
    data: function () {
      return {
        table: {
          header: [
            {
              label: 'Game ID',
              size: 'short',
            },
            {
              label: 'Date',
              size: 'medium',
            },
            {
              label: 'Event',
              size: 'flex',
            },
            {
              label: 'Court',
              size: 'medium',
            },
            {
              label: 'Status',
              size: 'medium',
            },
            {
              label: 'Event url',
              size: 'large',
            },
          ],
        },
      };
    },
    methods: {
      convertTime: TimeHelper.convertUtcToLocal,
    },
  };
</script>
